<template>
    <div class="b2b">

        <h1>
            Business to Business
        </h1>


    </div>
</template>

<script>
    export default {
        name: 'B2B',
    }
</script>

<style scoped lang="scss">

</style>