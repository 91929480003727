<template>
  <div class="about">

    <h1>
      Om os
    </h1>


  </div>
</template>

<script>
  export default {
    name: 'About',
  }
</script>

<style scoped lang="scss">

</style>