<template>
    <div class="inspiration">

        <h1>
            Inspiration
        </h1>


    </div>
</template>

<script>
    export default {
        name: 'Inspiration',
    }
</script>

<style scoped lang="scss">

</style>