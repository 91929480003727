<template>
    <div class="home">

        <section class="home_top">

            <div class="home_headings">
                <h1>Velkommen til BEMY</h1>
                <h2 class="h2_handwriting">Den personlige gave</h2>
            </div>

            <div class="main_home_container">

                <div class="home_infographic">

                    <router-link
                        :to="{ name: 'Wrapping' }"
                        class="infographic_element"
                    >

                        <img
                            src="../assets/images/info_1.jpg"
                            class="rounded_box"
                            alt="Eksempel på indpakning - æske med teksten 'Verdens bedste mor, Charlotte'"
                        >

                        <div class="infographic_icon_container">
                            <div class="infographic_icon">
                                <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="gift"
                                     class="svg-inline--fa fa-gift fa-w-16" role="img"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 512 512">
                                    <g class="fa-group">
                                        <path class="fa-secondary"
                                              d="M224 320v160H64a32 32 0 0 1-32-32V320zm0-160H32a32 32 0 0 0-32 32v80a16 16 0 0 0 16 16h208zm256 0H288v128h208a16 16 0 0 0 16-16v-80a32 32 0 0 0-32-32zM288 480h160a32 32 0 0 0 32-32V320H288z"
                                              opacity="0.4"></path>
                                        <path class="fa-primary"
                                              d="M224 480h64V320h-64zM359.54 32c-41.82 0-68.86 21.3-103.54 68.3-34.68-47-61.72-68.3-103.54-68.3C103.71 32 64 71.5 64 120a85.65 85.65 0 0 0 10.15 40h78.21a40 40 0 1 1 0-80c20 0 34.78 3.3 86.55 80H224v128h64V160h-15c51.67-76.5 66-80 86.55-80a40 40 0 1 1 0 80h78.31A86.86 86.86 0 0 0 448 120c0-48.5-39.71-88-88.46-88z"></path>
                                    </g>
                                </svg>
                            </div>
                            <div class="infographic_text">
                                1. Design din indpakning
                            </div>
                        </div>
                    </router-link>

                    <router-link
                        :to="{ name: 'ContentCategoriesOverview' }"
                        class="infographic_element">

                        <img
                            src="../assets/images/info_2.jpg"
                            class="rounded_box"
                            alt="Eksempel på indhold - æske med gin, chokolade og slikkepinde samt personligt gavebånd"
                        >

                        <div class="infographic_icon_container">
                            <div class="infographic_icon">
                                <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="tshirt"
                                     class="svg-inline--fa fa-tshirt fa-w-20" role="img"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 640 512">
                                    <g class="fa-group">
                                        <path class="fa-secondary"
                                              d="M8.86 96.5a16.11 16.11 0 0 0-7.2 21.5l57.23 114.5a16.11 16.11 0 0 0 21.5 7.2l17.32-8.48L26.09 88zm459-80.95a187.31 187.31 0 0 1-13.32 13.26C418.45 61.3 370.67 79.2 320 79.2s-98.41-17.9-134.51-50.39a187.31 187.31 0 0 1-13.32-13.26L54.76 73.75l71.69 143.4L137 212a16 16 0 0 1 23 14.4V480a32 32 0 0 0 32 32h256a32 32 0 0 0 32-32V226.3a16 16 0 0 1 23-14.4l10.57 5.18L585.19 73.7zm163.31 81l-17.32-8.59-71.6 143.19 17.24 8.45h.06a16 16 0 0 0 21.45-7.2L638.28 118a16 16 0 0 0-7.1-21.5z"
                                              opacity="0.4"></path>
                                        <path class="fa-primary"
                                              d="M320 47.2c-51.89 0-96.39-19.4-116.49-47.2l-31.34 15.55a187.31 187.31 0 0 0 13.32 13.26C221.59 61.3 269.37 79.2 320 79.2s98.43-17.9 134.53-50.39a187.31 187.31 0 0 0 13.32-13.26L436.51 0c-20.1 27.8-64.6 47.2-116.51 47.2zM26.09 88l71.62 143.22 28.74-14.07-71.69-143.4zm559.1-14.3l-71.68 143.38 28.74 14.07 71.61-143.24z"></path>
                                    </g>
                                </svg>
                            </div>
                            <div class="infographic_text">
                                2. Tilføj personligt indhold
                            </div>
                        </div>
                    </router-link>

                    <router-link
                        :to="{ name: 'MyPackages' }"
                        class="infographic_element">
                        <img
                            src="../assets/images/info_3.jpg"
                            class="rounded_box"
                            alt="Eksempler på forskellige pakker - en pose med teksten 'Inger, mom to be' og æske med
                            teksten 'It's a boy'"
                        >
                        <div class="infographic_icon_container">
                            <div class="infographic_icon">
                                <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="boxes"
                                     class="svg-inline--fa fa-boxes fa-w-18" role="img"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 576 512">
                                    <g class="fa-group">
                                        <path class="fa-secondary"
                                              d="M480 288v96l-32-21.3-32 21.3v-96zM320 0v96l-32-21.3L256 96V0zM160 288v96l-32-21.3L96 384v-96z"></path>
                                        <path class="fa-primary"
                                              d="M560 288h-80v96l-32-21.3-32 21.3v-96h-80a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h224a16 16 0 0 0 16-16V304a16 16 0 0 0-16-16zm-384-64h224a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-80v96l-32-21.3L256 96V0h-80a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16zm64 64h-80v96l-32-21.3L96 384v-96H16a16 16 0 0 0-16 16v192a16 16 0 0 0 16 16h224a16 16 0 0 0 16-16V304a16 16 0 0 0-16-16z"
                                              opacity="0.4"></path>
                                    </g>
                                </svg>
                            </div>
                            <div class="infographic_text">
                                3. Tilføj flere pakker
                            </div>
                        </div>
                    </router-link>

                    <div class="infographic_element">
                        <div class="infographic_icon_container">
                            <img
                                src="../assets/images/info_4.jpg"
                                class="rounded_box"
                                alt="Smilende kvinde som modtager en gaveæske"
                            >
                            <div class="infographic_icon">
                                <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="hands-heart"
                                     class="svg-inline--fa fa-hands-heart fa-w-20" role="img"
                                     xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 640 512">
                                    <g class="fa-group">
                                        <path class="fa-secondary"
                                              d="M608 64a32 32 0 0 0-32 32v148.4l-89.8 107.8a16.06 16.06 0 0 1-23.6 1.1l-12.8-12.8a16 16 0 0 1-1.5-20.9l38.1-50.8a32 32 0 1 0-51.2-38.4l-57.6 76.8A128.2 128.2 0 0 0 352 384v112a16 16 0 0 0 16 16h131.7a15.76 15.76 0 0 0 15.3-11.6 59.69 59.69 0 0 1 10.4-21.7l104.1-134.3a48.05 48.05 0 0 0 10.5-30V96a32 32 0 0 0-32-32zM204.8 230.42a32 32 0 1 0-51.2 38.4l38.1 50.8a16 16 0 0 1-1.5 20.9l-12.8 12.8a15.9 15.9 0 0 1-23.6-1.1L64 244.42V96a32 32 0 1 0-64 0v218.4a48.05 48.05 0 0 0 10.5 30l104.1 134.3a59.69 59.69 0 0 1 10.4 21.7 15.67 15.67 0 0 0 15.3 11.6H272a16 16 0 0 0 16-16V384a128.2 128.2 0 0 0-25.6-76.8z"
                                              opacity="0.4"></path>
                                        <path class="fa-primary"
                                              d="M198.4 136.32c-31.6-33.2-29.7-88.2 5.6-118.8 30.8-26.7 76.7-21.9 104.9 7.8l11.1 11.6 11.1-11.7c28.2-29.6 74.1-34.4 104.9-7.7 35.4 30.6 37.2 85.6 5.7 118.8l-108.9 114.2a17.42 17.42 0 0 1-25.5 0z"></path>
                                    </g>
                                </svg>
                            </div>
                            <div class="infographic_text">
                                4. Glæd dine kære
                            </div>
                        </div>
                    </div>
                </div>

                <div class="home_continue_buttons">
                    <router-link
                        :to="{ name: 'Wrapping' }">
                        <MainButton
                            :emph="false"
                            :text="'Design din egen pakke'"
                            :icon="'arrow_right'"
                        />
                    </router-link>

                    <a href="#our_packages"
                    >
                        <MainButton
                            :emph="true"
                            :text="'Bliv inspireret af vores pakker'"
                            :icon="'arrow_down'"
                        />
                    </a>
                </div>
            </div>

            <a
                href="#our_concept"
                class="arrow_down">
                <svg xmlns="http://www.w3.org/2000/svg" width="28.504" height="28.485" viewBox="0 0 28.504 28.485">
                    <title>Pil ned til indhold</title>
                    <g data-name="Group 36" transform="translate(-173 -561.586)">
                        <g transform="translate(173 418.733)">
                            <path data-name="Path 23"
                                  d="M14.25,162.324l-5.033,5.029L.628,158.79A2.117,2.117,0,0,1,.612,155.8l.016-.016,2.012-2a2.132,2.132,0,0,1,3.015,0Z"
                                  transform="translate(0 -0.146)" fill="#c59ead" opacity="0.4"/>
                            <path data-name="Path 24"
                                  d="M122.131,155.626l-2.03-2.01a2.132,2.132,0,0,0-3.015,0L103.47,167.207l3.521,3.508.005.005a2.137,2.137,0,0,0,3.022-.014l12.113-12.071.016-.015A2.118,2.118,0,0,0,122.131,155.626Z"
                                  transform="translate(-94.255)" fill="#c59ead"/>
                        </g>
                        <g data-name="angle-down-duotone" transform="translate(179 408.595)">
                            <path data-name="Path 23"
                                  d="M8.251,158.462l-2.914,2.912L.364,156.416a1.226,1.226,0,0,1-.009-1.733l.009-.009,1.165-1.161a1.234,1.234,0,0,1,1.746,0Z"
                                  transform="translate(0 -0.152)" fill="#c59ead" opacity="0.4"/>
                            <path data-name="Path 24"
                                  d="M114.275,154.517l-1.175-1.164a1.234,1.234,0,0,0-1.746,0l-7.884,7.87,2.038,2.031,0,0a1.238,1.238,0,0,0,1.75-.008l7.014-6.989.009-.009A1.226,1.226,0,0,0,114.275,154.517Z"
                                  transform="translate(-98.134)" fill="#c59ead"/>
                        </g>
                    </g>
                </svg>
            </a>

        </section>

        <section
            class="text_section"
            id="our_concept"
        >

            <div class="text_image rounded_box">
                <img
                    src="../assets/images/home_1.jpg"
                    alt="Gaveæske med indersidetekst, samt slippers, vingummier, gin, smykkepose og en slikkepind"
                >
            </div>

            <div class="text_element">
                <h2 class="h2_sub_section">
                    Vores koncept
                </h2>
                <h3 class="h3_handwriting">
                    Du designer &#8226; Vi leverer
                </h3>

                <p>
                    Hos BEMY har vi fokus på design; dit helt eget, personlige design.
                </p>

                <p>
                    Vores mål er, at det skal være nemt for dig at lave den helt perfekte, personlige gave, og få
                    den
                    leveret
                    direkte til modtageren. Vi har et hav af kvalitetsprodukter, som du kan personalisere med et af
                    vores
                    designs, modtagerens navn og dine egne tekster. Det eneste, du skal gøre, er at sammensætte din
                    pakke
                    - så klarer vi indpakningen og afsendelsen for dig.
                </p>

                <p>
                    <a href="#our_packages">
                        Vælg en af vores pakker forneden,
                    </a>
                    eller start rejsen ved at

                    <router-link
                        :to="{ name: 'Wrapping' }"
                    >
                        designe din egen indpakning.
                    </router-link>
                </p>

                <p>
                    Har du specielle krav, eller ønsker du et design, som vi ikke har?
                    <a class="not_allowed"
                    >
                        Så hører vi gerne fra dig!
                    </a>
                </p>
            </div>
        </section>

        <section
            class="text_section"
        >

            <div class="text_element">
                <h2 class="h2_sub_section">
                    BEMY & B2B
                </h2>

                <p>
                    Er du ved at planlægge årets firmajulegaver, 20 års jubilæum, eller fortjener dine medarbejdere bare
                    lidt forkælelse?
                </p>

                <p>
                    Med en personlig gave fra BEMY er det nemt at vise dine medarbejdere, hvor meget du sætter pris på
                    dem,
                    da de alle får noget helt unikt - og med vores pakke-system her på hjemmesiden er det nemt og
                    hurtigt
                    at sætte pakkerne sammen til dem alle.
                </p>
                <p>
                    Hvis du har andre ønsker, såsom indpakninger til dine produkter med dit eget logo,
                    <a class="not_allowed"
                    >
                        hører vi gerne fra dig,
                    </a>
                    og så skal vi nok finde en løsning.
                </p>
            </div>

            <div class="text_image rounded_box">
                <img
                    src="../assets/images/home_2.jpg"
                    alt="Fire ens gaveæsker med firmalogo">
            </div>

        </section>


        <section id="our_packages">
            <div>
                <h2 class="h2_sub_section">
                    Gør en af vores pakker til din helt egen
                </h2>

                <h3>
                    Vælg en pakke, og personalisér til den er perfekt
                </h3>

                <div class="limit_text_width">

                    <p>
                        Søger du gaven til brudepigerne? Konfirmanden? Medarbejderne?
                        Vi ved, hvor vigtigt det er, at skabe den perfekte gave, og derfor vil vi gerne hjælpe dig på
                        vej.
                    </p>

                    <p>
                        Bliv inspireret af vores pakker, eller tilføj en pakke direkte til din egen pakke. Derefter kan
                        du
                        selv
                        ændre og personalisere alt, du har lyst til, og tilføje så mange personer, du har brug for.
                    </p>
                    <p class="developer_note">
                        <strong>
                            Developer note:
                        </strong>
                        Nedenstående er et mockup af, hvordan færdige pakker skal se ud på desktop. Når man trykker på
                        en
                        "kategori", dukker de færdige pakker i denne kategori op. Tryk for at få en "demo".
                    </p>
                </div>
            </div>

            <div
                class="our_packages_container"
                @click="updatePackagesPlaceholder"
            >

                <img :src="ourPackagesPlaceholder"
                     alt="Placeholder billede af, hvordan 'Vores færdige pakker' kunne se ud"
                >

            </div>
        </section>


    </div>
</template>

<script>
    import MainButton from "../components/MainButton";

    export default {
        name: 'Home',
        components: {MainButton},
        data() {
            return {
                ourPackagesPlaceholder: require('./../assets/placeholders/our-packages.jpg'),
            }
        },
        methods: {
            updatePackagesPlaceholder() {
                console.log("ye");
                if (this.ourPackagesPlaceholder === require('./../assets/placeholders/our-packages.jpg')) {
                    console.log("closed");
                    this.ourPackagesPlaceholder = require('./../assets/placeholders/our-packages-open.jpg')
                } else if (this.ourPackagesPlaceholder === require('./../assets/placeholders/our-packages-open.jpg')) {
                    console.log("open");
                    this.ourPackagesPlaceholder = require('./../assets/placeholders/our-packages.jpg')
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    .home_top {
        min-height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    h1 {
        text-align: center;
        margin-bottom: 0;
    }

    .h2_handwriting {
        text-align: center;
        font-family: var(--font-family-handwriting);
        font-weight: 400;
        font-size: 22px;
        color: rgba(var(--colour-grey-700), 1);
        margin: 0;
    }


    .home_infographic {
        display: grid;
        align-items: center;
        justify-content: space-around;
        font-family: var(--font-family-header);
        color: rgba(var(--colour-grey-800), 1);
        padding: 24px 48px 0 48px;
    }

    .infographic_element {
        text-decoration: none;
        color: rgba(var(--colour-grey-800), 1);
    }

    .infographic_icon_container {
        text-align: center;
    }

    .infographic_icon {
        transform: translateY(-50%);
        background-color: rgba(var(--colour-secondary-100), 1);
        display: inline-block;
        flex: 1;
        box-sizing: border-box;
        border-radius: 100%;
        height: 64px;
        width: 64px;
        padding: 16px;
        box-shadow: var(--box-shadow-400);
        margin: 0 0 12px 0;

        svg {
            height: 100%;
            width: 100%;
            fill: rgba(var(--colour-primary-400), 1);
        }
    }

    .infographic_text {
        transform: translateY(-35px);
    }

    .home_continue_buttons {
        max-width: 1000px;
        margin: 24px auto;

        .main_button {
            padding: 16px;
            text-transform: uppercase;
            margin-top: 12px;
            font-weight: 600;
            width: 100%;
        }

        a {
            text-decoration: none;
            width: 100%;
        }
    }

    .arrow_down {
        display: none;
        width: fit-content;
        margin: 0 auto;

        animation-name: arrow_down_kf;
        animation-iteration-count: infinite;
        animation-fill-mode: forwards;
        animation-direction: alternate;
        animation-duration: 1s;
        animation-timing-function: ease-in;
    }

    @keyframes arrow_down_kf {
        0% {
            transform: none;
        }

        100% {
            transform: scale(1.1);
        }
    }

    .h2_sub_section {
        font-size: 24px;
        font-weight: 400;
    }

    .our_packages_container {
        cursor: pointer;
    }

    .h3_handwriting {
        font-family: var(--font-family-handwriting);
        font-weight: 400;
        font-size: 30px;
        color: rgba(var(--colour-grey-700), 1);
        margin: 4px 0;
    }

    .text_section {
        margin: 64px 0;
        display: grid;
        grid-gap: 24px;
    }

    .text_image {
        height: fit-content;
    }

    @media screen and (min-width: 768px) {
        /* Header */
        .h2_handwriting {
            font-size: 32px;
        }

        /* Infographic */
        .home_infographic {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 48px;
            font-size: 16px;
            padding: 0;
        }
        .infographic_text {
            transform: translateY(-50px);
        }

        .infographic_icon {
            height: 96px;
            width: 96px;
            padding: 24px;
        }

        .arrow_down {
            display: block;
            text-align: center;
            margin-bottom: 24px;

            svg {
                height: 36px;
                width: 36px;
            }
        }

        .home_continue_buttons {
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;

            .main_button {
                padding: 24px;
                width: 100%;
                text-transform: uppercase;
            }

            a {
                text-decoration: none;

                + a {
                    margin-right: 24px;
                }
            }
        }

        .text_section {
            grid-template-columns: 1fr 2fr;
            grid-gap: 0;
            margin: 0 auto;

            .text_image {
                margin: 96px 0 0 0;
            }

            .text_element {
                padding-top: 96px;
                margin: auto 0 auto 48px;
            }

            + .text_section {
                grid-template-columns: 2fr 1fr;

                .text_image {
                    margin: 96px 0;
                }

                .text_element {
                    padding-top: 0;
                    margin: auto 48px auto 0;
                }
            }
        }

        .limit_text_width {
            max-width: 750px;
        }
    }


    @media screen and (min-width: 1024px) {

        .text_section {
            .text_element {
                margin: auto 0 auto 96px;
            }

            + .text_section {
                .text_element {
                    margin: auto 96px auto 0;
                }
            }
        }
    }

</style>