<template>
    <div class="delivery">

        <h1>
            Levering
        </h1>


    </div>
</template>

<script>
    export default {
        name: 'Delivery',
    }
</script>

<style scoped lang="scss">

</style>